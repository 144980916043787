import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/meddbtech.github.io/meddbtech.github.io/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "meddbtech-co-ltd"
    }}>{`Meddbtech Co., Ltd.`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAADBf/EABYBAQEBAAAAAAAAAAAAAAAAAAMAAf/aAAwDAQACEAMQAAABaTBYou5Fl//EABoQAAIDAQEAAAAAAAAAAAAAAAIRAQMiABL/2gAIAQEAAQUCaJKys8+9SeBtke//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8BDZ//xAAYEQEBAAMAAAAAAAAAAAAAAAABAAIRUf/aAAgBAgEBPwFyS09v/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECESIxQWH/2gAIAQEABj8Ctx8ux2sX0x0Rjs10dLp//8QAGxABAQEBAAMBAAAAAAAAAAAAAREAITFBUcH/2gAIAQEAAT8hppqx8Mro6kfuqfIcwhBCe9I85NBFO//aAAwDAQACAAMAAAAQYw//xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhUf/aAAgBAwEBPxBNhxh//8QAGBEBAAMBAAAAAAAAAAAAAAAAAQARMaH/2gAIAQIBAT8QJSoVa5P/xAAdEAACAgMAAwAAAAAAAAAAAAABEQAhMUFhcYGh/9oACAEBAAE/ECMAyNS9aaEMTy9xSXF/InJV3YWj2DxAPC1iBIJhVE+3AA6IBY5P/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/5e8f9172631db490aa646bc3c66c9ffe/a3012/image1.webp 360w", "/static/5e8f9172631db490aa646bc3c66c9ffe/bc514/image1.webp 720w", "/static/5e8f9172631db490aa646bc3c66c9ffe/f992d/image1.webp 1440w", "/static/5e8f9172631db490aa646bc3c66c9ffe/62ed8/image1.webp 1800w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/5e8f9172631db490aa646bc3c66c9ffe/158ba/image1.jpg 360w", "/static/5e8f9172631db490aa646bc3c66c9ffe/80e3c/image1.jpg 720w", "/static/5e8f9172631db490aa646bc3c66c9ffe/644c5/image1.jpg 1440w", "/static/5e8f9172631db490aa646bc3c66c9ffe/d9c39/image1.jpg 1800w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/5e8f9172631db490aa646bc3c66c9ffe/644c5/image1.jpg",
            "alt": "Alt Image Text 1",
            "title": "Alt Image Text 1",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2 {...{
      "id": "service"
    }}>{`Service`}</h2>
    <h3 {...{
      "id": "web-application"
    }}>{`Web application`}</h3>
    <p>{`Meddbtech specialize in building applications for the web.
We’ve had experience from static websites through to complex online data web application serving thousands of customers all at once.`}</p>
    <ul>
      <li parentName="ul">{`Highly scalable public web sites`}</li>
      <li parentName="ul">{`Rich, modern, fast interfaces optimized for ease of use and conversions`}</li>
      <li parentName="ul">{`Custom built for your unique business needs`}</li>
    </ul>
    <h3 {...{
      "id": "mobile-application-cross-platform"
    }}>{`Mobile application (Cross-Platform)`}</h3>
    <p>{`Meddbtech builds mobile apps for iPad, iPhone, Android and tablet devices.
Almost everyone owning a smartphone, it’s time to investigate the opportunities this presents.`}</p>
    <p>{`We’re experts on new techniques such as responsive design
(websites that automatically adjust when viewed on a mobile device) and are pioneers of new technologies.`}</p>
    <p>{`Our areas of mobile expertise include:`}</p>
    <ul>
      <li parentName="ul">{`Responsive web sites`}</li>
      <li parentName="ul">{`Apps built for offline use with synchronization`}</li>
      <li parentName="ul">{`Cross-platform mobile apps built with Ionic`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      